.page-header {
  color: #fff;
  text-align: center;
  /* background-color: #159957;
  background-image: linear-gradient(120deg, #155799, #159957); */
  padding: 3rem 4rem;
}

@media screen and (min-width: 64em) {
  .page-header {
    padding: 5rem 6rem;
  }
}

@media screen and (min-width: 42em) and (max-width: 64em) {
  .page-header {
    padding: 3rem 4rem;
  }
}

.hot-item {
  color: #606c71;
}

.hot-item a {
  font-size: 1.1em;
  color: #1e6bb8;
  text-decoration: none;
}
